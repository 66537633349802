import Conversion, {
  type ConversionProps,
} from '@/components/brochureV2/section/Conversion/Conversion';
import {ColorSchemeEnum} from '@/enums';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import {useTranslations} from '@/hooks/useTranslations';

export default function PosConversion(props: ConversionProps) {
  const {t} = useTranslations();
  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();
  const contactSalesCta = isRetailUnifiedFunnelExperiment
    ? {
        cta: {
          buttonPrimary: {
            url: '#contact-sales',
            text: t('pos:components.contactSalesCta'),
          },
          ctaDisclaimer: props.cta?.ctaDisclaimer,
        },
      }
    : {};

  const eyebrowClass =
    props.colorScheme === ColorSchemeEnum.PosDark
      ? 'text-lime-50'
      : 'text-lime-70';
  return (
    <Conversion {...props} {...contactSalesCta} eyebrowClass={eyebrowClass} />
  );
}
